import React, { useState, useEffect, useRef } from 'react';
import NavBar from '../components/Navbar/navbar';
import AboutSlider from '../components/Home/AboutSlider';
import HeroContainer from '../components/Home/HeroContainer';
import Services from '../components/Home/Services/Services';
import TokenInfoSection from '../components/Home/TokenInfoSection';
import StepsToStartTrading from '../components/Home/StepsToStartTrading';
import Footer from '../components/Footer/Footer';
import Meta from '../components/Meta';
import Head from 'next/head';
import CookieConsent from '../components/CookieConsent'; 
import WhatAreTokenizedAssets from '../components/Home/WhatAreTokenizedAssets';

const IntroductionPage = () => {
  const [isStepsVisible, setIsStepsVisible] = useState(false);
  const [isTokenInfoVisible, setIsTokenInfoVisible] = useState(false);
  const [isTokenizedAssetsVisible, setIsTokenizedAssetsVisible] = useState(false);
  const [isTokenizationBenefitsVisible, setIsTokenizationBenefitsVisible] = useState(false);

  const stepsRef = useRef<HTMLDivElement | null>(null);
  const tokenInfoRef = useRef<HTMLDivElement | null>(null);
  const tokenizedAssetsRef = useRef<HTMLDivElement | null>(null);
  const tokenizationBenefitsRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.target === stepsRef.current && entry.isIntersecting) {
            setIsStepsVisible(true);
          }
          if (entry.target === tokenInfoRef.current && entry.isIntersecting) {
            setIsTokenInfoVisible(true);
          }
          if (entry.target === tokenizedAssetsRef.current && entry.isIntersecting) {
            setIsTokenizedAssetsVisible(true);
          }
          if (entry.target === tokenizationBenefitsRef.current && entry.isIntersecting) {
            setIsTokenizationBenefitsVisible(true);
          }
        });
      },
      { threshold: 0.5 }
    );

    const refs = [stepsRef, tokenInfoRef, tokenizedAssetsRef, tokenizationBenefitsRef];

    refs.forEach(ref => {
      if (ref.current) observer.observe(ref.current);
    });

    return () => {
      refs.forEach(ref => {
        if (ref.current) observer.unobserve(ref.current);
      });
    };
  }, []);

  return (
    <>
      <Head>
        <title>Home | PINX Global Markets</title>
        <Meta title="PINX Global Markets" />
        <link rel="icon" href="/assets/PinxLogo.png" type="image/png" />
      </Head>

      <NavBar />
      <CookieConsent />
      <HeroContainer />
      <AboutSlider />

      <div ref={tokenizedAssetsRef}>
        <WhatAreTokenizedAssets isVisible={isTokenizedAssetsVisible} />
      </div>

{/*
      <div ref={tokenizationBenefitsRef}>
        <TokenizationBenefits isVisible={isTokenizationBenefitsVisible} />
      </div>
      <TokenizationFlow />
*/}

      <Services />

      <div ref={tokenInfoRef}>
        <TokenInfoSection isTokenInfoVisible={isTokenInfoVisible} />
      </div>

      <div ref={stepsRef}>
        <StepsToStartTrading isStepsVisible={isStepsVisible} />
      </div>

      <Footer />
    </>
  );
};

export default IntroductionPage;

import React, { useState, useEffect, useRef } from "react";
import { motion } from "framer-motion";
import { LineChart, ScrollText, Gem, Building2, DollarSign, BarChart4 } from "lucide-react";

export default function WhatAreTokenizedAssets({ isVisible }) {
  const assets = [
    { name: "Stocks & Securities", icon: <LineChart /> },
    { name: "Bonds", icon: <ScrollText /> },
    { name: "Commodities", icon: <Gem /> },
    { name: "Real Estate & Land", icon: <Building2 /> },
    { name: "Currencies", icon: <DollarSign /> },
    { name: "Derivatives", icon: <BarChart4 /> },
  ];

  const [showIcons, setShowIcons] = useState(false);
  const canvasRef = useRef(null);
  let smokeParticles = [];

  useEffect(() => {
    if (isVisible) {
      const timer = setTimeout(() => setShowIcons(true), 2000);
      startSmokeEffect();
      return () => {
        clearTimeout(timer);
        stopSmokeEffect();
      };
    } else {
      setShowIcons(false);
      stopSmokeEffect();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  const startSmokeEffect = () => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    const ctx = canvas.getContext("2d");
    canvas.width = 200;
    canvas.height = 200;
    
    function createSmokeParticle() {
      return {
        x: 100 + Math.random() * 20 - 10,
        y: 160,
        radius: Math.random() * 8 + 4,
        opacity: 1,
        speed: Math.random() * 2 + 1,
      };
    }
    
    function updateSmoke() {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      smokeParticles.forEach((p, i) => {
        p.y -= p.speed;
        p.opacity -= 0.02;
        if (p.opacity <= 0) smokeParticles.splice(i, 1);
      });
      smokeParticles.push(createSmokeParticle());
      drawSmoke();
    }

    function drawSmoke() {
      smokeParticles.forEach((p) => {
        ctx.beginPath();
        ctx.arc(p.x, p.y, p.radius, 0, Math.PI * 2);
        ctx.fillStyle = `rgba(255, 255, 255, ${p.opacity})`;
        ctx.fill();
      });
    }

    function animateSmoke() {
      updateSmoke();
      if (!showIcons) requestAnimationFrame(animateSmoke);
    }
    animateSmoke();
  };

  const stopSmokeEffect = () => {
    smokeParticles = [];
  };

  return (
    <section className="flex flex-col items-center text-center px-6 py-12 bg-gradient-radial from-gray-900 via-purple-900 to-black w-full">
      <h2 className="text-3xl sm:text-4xl lg:text-5xl font-extrabold text-white drop-shadow-2xl">
        What Assets Can Be Tokenized?
      </h2>
      <p className="mt-4 text-sm sm:text-base lg:text-lg text-gray-300 max-w-2xl">
        Any real-world asset can be tokenized, allowing for efficient tracking, trading, and secure transfer in digital markets.
      </p>

      {!showIcons && isVisible && (
        <motion.div
          initial={{ opacity: 1, scale: 1 }}
          animate={{ opacity: 0, scale: 1.2 }}
          transition={{ duration: 1.7 }}
          className="mt-8 text-7xl sm:text-8xl lg:text-9xl font-bold text-white relative"
        >
          ?
          <canvas ref={canvasRef} className="absolute left-1/2 transform -translate-x-1/2 bottom-[-20px]"></canvas>
        </motion.div>
      )}

      {showIcons && (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
          className="mt-8 grid grid-cols-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-6 gap-1.5 sm:gap-4 lg:gap-6 w-full max-w-screen-lg"
        >
          {assets.map((asset, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: index * 0.2 }}
              className="flex flex-col items-center space-y-2 p-2 sm:p-4"
            >
              <div className="flex items-center justify-center rounded-full border-2 sm:border-3 lg:border-4 border-white bg-gray-800 shadow-lg
                w-16 h-16 sm:w-20 sm:h-20 lg:w-24 lg:h-24">
                <div className="text-white">
                  {React.cloneElement(asset.icon, { size: 30, className: "sm:size-36 lg:size-40" })}
                </div>
              </div>
              <p className="text-xs sm:text-sm lg:text-base font-semibold text-white">{asset.name}</p>
            </motion.div>
          ))}
        </motion.div>
      )}
    </section>
  );
}

declare global {
  interface Window {
    VANTA: any;
    THREE: any;
  }
}

import React, { useEffect, useRef } from 'react';
import Typed from 'typed.js';
import { FaApple } from 'react-icons/fa'; 

const HeroContainer = () => {
  const heroRef = useRef(null);

  const loadVantaEffect = () => {
    if (!window.THREE) {
      const threeScript = document.createElement('script');
      threeScript.src = 'https://unpkg.com/three@0.134.0/build/three.min.js';
      threeScript.onload = () => {
        loadVanta();
      };
      document.body.appendChild(threeScript);
    } else {
      loadVanta();
    }
  };

  const loadVanta = () => {
    if (!window.VANTA) {
      const vantaScript = document.createElement('script');
      vantaScript.src = 'https://unpkg.com/vanta/dist/vanta.globe.min.js';
      vantaScript.onload = () => {
        applyVanta();
      };
      document.body.appendChild(vantaScript);
    } else {
      applyVanta();
    }
  };

  const applyVanta = () => {
    window.VANTA.GLOBE({
      el: heroRef.current,
      mouseControls: true,
      touchControls: true,
      gyroControls: false,
      minHeight: 200.00,
      minWidth: 200.00,
      scale: 1.00,
      scaleMobile: 1.00,
      color: 0xff00ff,
      backgroundColor: 0x000000,
      backgroundAlpha: 0.0,
    });
  };

  useEffect(() => {
    loadVantaEffect();

    return () => {
      if (window.VANTA) {
        if (window.VANTA.animations) {
          window.VANTA.animations.forEach((animation) => animation.destroy());
          window.VANTA.animations = [];
        }
      }
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
<style jsx global>{`
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.05);
    }
    100% {
      transform: scale(1);
    }
  }
  .vanta-canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
`}</style>


<div
  ref={heroRef}
  className="
    relative flex flex-col justify-center items-center text-center text-white 
    h-[600px] px-[100px] 
    max-1300:px-[80px] 
    max-1150:px-[60px] 
    max-1000:px-[50px] 
    max-800:px-[40px] max-800:h-auto 
    max-600:px-[30px] 
    max-400:px-5 max-400:py-10
    w-full max-w-[100vw]
  "
>

        <DynamicTypingHeading />
        <p
  className="
    text-white 
    mb-10 
    text-[1.5rem] 
    max-800:text-[1.3rem] 
    max-400:text-[1rem] 
    [text-shadow:2px_2px_5px_rgba(0,0,0,0.7)]
  "
>
  Your Financial Journey, Amplified by Our Technology.
</p>

        
        
        
<div className="flex flex-row flex-nowrap justify-center gap-3 w-full max-w-[600px] mx-auto mb-6 px-2 sm:px-4">

  {[
    { href: "https://x.com/pinxtoken", label: "Twitter" },
    { href: "https://t.me/pinxglobalmarket", label: "Telegram" },
    {
      href: "https://dexscreener.com/solana/6m32vkef22fzvv2nc69wnabdax7wdn4zsggwjujurqev",
      label: "Buy $PINX",
    },
  ].map(({ href, label }) => (
<a
  key={label}
  href={href}
  className="whitespace-nowrap 
    px-6 py-3 text-[1.2rem]
    max-768:px-5 max-768:py-2.5 max-768:text-[1.1rem] 
    max-500:px-4 max-500:py-2.5 max-500:text-base 
    max-450:px-3 max-450:py-2 max-450:text-sm 
    max-350:px-2.5 max-350:py-1.5 max-350:text-xs 
    font-bold text-white rounded-md 
    bg-gradient-to-b from-gray-700/80 to-gray-800/90 
    shadow-xl transition-all duration-300 
    hover:bg-[#9e89b3] 
hover:shadow-[0_10px_25px_rgba(184,169,201,0.3),0_15px_30px_rgba(218,165,32,0.4)]

    hover:text-white"
    
>

  {label}
</a>

  ))}
</div>


<div className="flex justify-center w-full px-4">
  <a
    href="https://apps.apple.com/us/app/pinx-global-markets/id6670228875"
    className="flex items-center justify-center w-full max-w-[400px] px-4 py-3 sm:px-6 sm:py-4 font-bold text-white text-base sm:text-lg md:text-xl rounded-md text-center bg-[#0070ff] shadow-xl hover:bg-[#0056b3] hover:shadow-[0_0_30px_rgba(0,112,255,0.8),0_0_30px_rgba(255,255,255,0.4)] animate-[pulse_2s_infinite] transition-all"
  >
    <FaApple className="mr-2 w-5 h-5 sm:w-6 sm:h-6" />
    <span className="text-sm sm:text-base md:text-lg">
      Now Available on iOS
    </span>
  </a>
</div>

      </div>

    </>
  );
};

export default HeroContainer;

const DynamicTypingHeading = () => {
  const el = useRef(null);

  useEffect(() => {
    const typed = new Typed(el.current, {
      strings: ['PINX GLOBAL MARKETS'],
      typeSpeed: 30,
      backSpeed: 50,
      backDelay: 1500,
      startDelay: 300,
      loop: false,
      showCursor: true,
      cursorChar: '',
    });

    return () => {
      typed.destroy();
    };
  }, []);

  return (
    <div
      ref={el}
      className="text-white font-bold mb-5 text-4xl sm:text-5xl md:text-6xl [text-shadow:2px_2px_5px_rgba(0,0,0,0.7)]"
    />
  );
  
};
